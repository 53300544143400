import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { styled, Tab, Tabs, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useQuery } from '@tanstack/react-query';
import { addDays, endOfDay, isValid, startOfDay } from 'date-fns';

import { getDasEndpoints } from '../../../apis/dasEndpointApi';
import { getBleAoas, getDasbeacons, getUwbAoas } from '../../../apis/deviceApi';

import { parseDasId } from '../../../utils/common';

import AlertReportContent from './AlertReportContent';
import GeneralReportContent from './GeneralReportContent';
import NetworkReportContent from './NetworkReportContent';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;

  & pre {
    width: 100%;
    height: 300px;
    overflow: auto;
    white-space: pre-wrap;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
    border-radius: 10px;
    padding: 16px;
    background: #fff;
  }

  & > .property-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  & .time-range-control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding: 16px;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

const Detail = () => {
  const { dasId } = useParams();
  const [fromDate, setFromDate] = useState(startOfDay(addDays(new Date(), -7)));
  const [toDate, setToDate] = useState(addDays(startOfDay(new Date()), 1));
  const [isRawDataExpand, setIsRawDataExpand] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [filterText, setFilterText] = useState('');

  const deviceType = parseDasId(dasId);

  const enableQuery =
    deviceType === 'dasloop' ||
    deviceType === 'dasloop_video' ||
    deviceType === 'dastrack' ||
    deviceType === 'dastrack_v' ||
    deviceType === 'dascas_g' ||
    deviceType === 'das_ai_box';

  const enableGeneralReportQuery =
    deviceType === 'dasloop' ||
    deviceType === 'dasloop_video' ||
    deviceType === 'dastrack' ||
    deviceType === 'dastrack_v' ||
    deviceType === 'daspower' ||
    deviceType === 'dastemp' ||
    deviceType === 'dasair' ||
    deviceType === 'das_aoa_tag' ||
    deviceType === 'daswater' ||
    deviceType === 'dasgas' ||
    deviceType === 'daslock' ||
    deviceType === 'dascas_g' ||
    deviceType === 'dasconcrete' ||
    deviceType === 'das_ai_box';

  const enalbeAlertReportQuery =
    deviceType === 'dasloop' ||
    deviceType === 'dasloop_video' ||
    deviceType === 'dastrack' ||
    deviceType === 'daspower' ||
    deviceType === 'dastemp' ||
    deviceType === 'dasair' ||
    deviceType === 'daswater' ||
    deviceType === 'dasgas' ||
    deviceType === 'daslock' ||
    deviceType === 'dascas_g' ||
    deviceType === 'dasconcrete' ||
    deviceType === 'das_ai_box';

  const { data: dasendpoint } = useQuery({
    queryKey: ['get-dasendpoint', dasId],
    queryFn: async () => {
      const res = await getDasEndpoints({
        dasId,
      });
      return res.data.data[0];
    },
    enabled:
      deviceType === 'dasloop' ||
      deviceType === 'dasloop_video' ||
      deviceType === 'dastrack' ||
      deviceType === 'dastrack_v' ||
      deviceType === 'daspower' ||
      deviceType === 'dastemp' ||
      deviceType === 'dasair' ||
      deviceType === 'das_aoa_tag' ||
      deviceType === 'daswater' ||
      deviceType === 'dasgas' ||
      deviceType === 'daslock' ||
      deviceType === 'dascas' ||
      deviceType === 'dascas_g' ||
      deviceType === 'dasconcrete' ||
      deviceType === 'das_ai_box',
    refetchOnWindowFocus: false,
  });

  const { data: dasbeacon } = useQuery({
    queryKey: ['get-dasbeacon', dasId],
    queryFn: async () => {
      const res = await getDasbeacons({
        dasId,
      });
      return res.data.data[0];
    },
    enabled: deviceType === 'dasbeacon',
    refetchOnWindowFocus: false,
  });

  const { data: bleAoa } = useQuery({
    queryKey: ['get-ble-aoa', dasId],
    queryFn: async () => {
      const res = await getBleAoas({
        dasId,
      });
      return res.data.data[0];
    },
    enabled: deviceType === 'ble_aoa',
    refetchOnWindowFocus: false,
  });

  const { data: uwbAoa } = useQuery({
    queryKey: ['get-uwb-aoa', dasId],
    queryFn: async () => {
      const res = await getUwbAoas({
        dasId,
      });
      return res.data.data[0];
    },
    enabled: deviceType === 'uwb_aoa',
    refetchOnWindowFocus: false,
  });

  const filterRegex = useMemo(() => {
    if (filterText) {
      return new RegExp(`.*${filterText}.*`);
    }
  }, [filterText]);

  const device = dasendpoint || dasbeacon || bleAoa || uwbAoa;

  return (
    <Container>
      <div>{dasId}</div>
      <div className="property-container">
        <div>UUID: {device?.id}</div>
        <div>
          Owner: {device?.owner?.firstName} {device?.owner?.lastName}
          {device?.owner?.email && `(${device?.owner?.email})`}
        </div>
        <div>
          Project: {device?.project?.name}
          {device?.project?.archived && '(Archived)'}
        </div>
      </div>
      {isRawDataExpand && (
        <div>
          <Typography component="h2">Raw Data:</Typography>
          <pre>{JSON.stringify(device, null, 2)}</pre>
        </div>
      )}
      <DialogButton
        variant="contained"
        color="primary"
        onClick={() => setIsRawDataExpand(!isRawDataExpand)}
      >
        {isRawDataExpand ? 'Hide' : 'Expand'} Raw Data
        {isRawDataExpand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </DialogButton>
      <Tabs
        value={tabIndex}
        onChange={(_, index) => {
          setTabIndex(index);
        }}
      >
        <Tab label="General Report" />
        <Tab label="Alert Report" />
        <Tab label="Network Report" />
      </Tabs>
      <div className="time-range-control">
        <div>
          From:
          <DateTimePicker
            value={isValid(fromDate) ? fromDate : null}
            onChange={(v) => {
              if (v) {
                setFromDate(startOfDay(v));
              }
            }}
          />
        </div>
        <div>
          To:
          <DateTimePicker
            value={isValid(toDate) ? toDate : null}
            onChange={(v) => {
              if (v) {
                setToDate(endOfDay(v));
              }
            }}
          />
        </div>
        <div>
          Filter:{' '}
          <TextField
            value={filterText}
            onChange={(e) => setFilterText(e.currentTarget.value)}
          />
        </div>
      </div>
      {dasId && tabIndex === 2 && (
        <NetworkReportContent
          dasId={dasId}
          fromDate={fromDate}
          toDate={toDate}
          enableQuery={enableQuery}
          filterRegex={filterRegex}
        />
      )}
      {dasendpoint && tabIndex === 0 && (
        <GeneralReportContent
          id={dasendpoint.id}
          fromDate={fromDate}
          toDate={toDate}
          enableQuery={enableGeneralReportQuery}
          filterRegex={filterRegex}
        />
      )}
      {dasendpoint && tabIndex === 1 && (
        <AlertReportContent
          id={dasendpoint.id}
          fromDate={fromDate}
          toDate={toDate}
          enableQuery={enalbeAlertReportQuery}
          filterRegex={filterRegex}
        />
      )}
    </Container>
  );
};

export default Detail;
