import { FC, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { ErrorState } from '../../../types';
import { DasEndpoint, DasEndpointType } from '../../../types/Device';
import { CreateIssue } from '../../../types/RmrTicket';

import { getDasEndpoints } from '../../../apis/dasEndpointApi';

import DeviceAssignment from '../../../components/DeviceAssignment';
import DeviceTag from '../../../components/DeviceTag';
import Input from '../../../components/Input';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';
import Textarea from '../../../components/Textarea';

import { inputSx, rmrIssueCloseButtonSx } from '../../../theme';
import { parseDasId } from '../../../utils/common';

const Container = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    backgroundColor: theme.color.gray.$250,
    padding: '16px 20px',
    border: `1px solid ${theme.color.gray.$235}`,
    borderRadius: '8px',

    '& > .edit-block': {
      marginBottom: '16px',
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& > label': {
        fontSize: '0.875rem',
        color: theme.color.secondary.$60,
        '& > span': {
          color: '#FF6B00',
        },
      },
      '& > .device-wrapper': {
        margin: '8px 0 16px 0',
        '& > .container': {
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '8px',
          marginTop: '8px',
        },
      },
    },
  };
});

interface EditIssueViewProps {
  issueIndex: number;
  onChange?: (issue: CreateIssue) => void;
  onDelete?: (index: number) => void;
}

const EditIssueView: FC<EditIssueViewProps> = ({
  issueIndex,
  onChange,
  onDelete,
}) => {
  const [reportIssue, setReportIssue] = useState('');
  const [reportIssueError, setReportIssueError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [filter, setFilter] = useState('');
  const [selectedDasIds, setSelectedDasIds] = useState<string[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onChange?.({
        title: reportIssue,
        dasIds: selectedDasIds,
        description,
        isValid:
          reportIssue !== '' && selectedDasIds.length > 0 && description !== '',
      });
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [reportIssue, selectedDasIds, description]);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['get-dasendpoint', filter],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const res = await getDasEndpoints({
        nextCursor: pageParam as string | null,
        limit: 10,
        dasId: filter,
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => lastPage?.paging?.nextCursor || undefined,
    refetchOnWindowFocus: false,
    initialPageParam: undefined,
  });

  const dasendpoints = useMemo(() => {
    return data?.pages
      .map((page) => {
        return page.data;
      })
      .flat()
      .reduce<{ [dasId: string]: DasEndpoint }>((prev, dasendpoint) => {
        prev[dasendpoint.dasId] = dasendpoint;
        return prev;
      }, {});
  }, [data?.pages, selectedDasIds]);

  const handleOnDeleteSelectedDasId = (dasId: string) => {
    setSelectedDasIds(selectedDasIds.filter((id) => id !== dasId));
  };

  const deviceTags = selectedDasIds.map((dasId) => {
    return (
      <DeviceTag
        key={`devicetags-${issueIndex}-${dasId}`}
        dasId={dasId}
        mode="edit"
        type={parseDasId(dasId) as DasEndpointType}
        onDelete={handleOnDeleteSelectedDasId}
      />
    );
  });

  const handleOnSelect = (dasId: string) => {
    setSelectedDasIds([...selectedDasIds, dasId]);
  };

  return (
    <Container className="edit-issue-view">
      {issueIndex !== 0 && (
        <CrossSvgIcon
          sx={rmrIssueCloseButtonSx}
          onClick={() => onDelete?.(issueIndex)}
        />
      )}
      <div className="edit-block">
        <label>
          Report Issue {issueIndex + 1}
          <span>*</span>
        </label>
        <Input
          sx={{ ...inputSx, '& > input': { backgroundColor: '#fff' } }}
          value={reportIssue}
          onChange={(e) => setReportIssue(e.currentTarget.value)}
          onBlur={() => {
            if (reportIssue === '') {
              setReportIssueError({
                isError: true,
                message: 'Report issue do not empty',
              });
            } else {
              setReportIssueError({
                isError: false,
                message: '',
              });
            }
          }}
          error={reportIssueError.isError}
          errorMessage={reportIssueError.message}
        />
      </div>
      <div className="edit-block">
        <label>
          Associated Devices<span>*</span>
        </label>
        <div className="device-wrapper">
          <DeviceAssignment
            prefixKey={`create-issue-${issueIndex + 1}`}
            filter={filter}
            assignMap={dasendpoints}
            onSelect={handleOnSelect}
            selectedList={selectedDasIds}
            onFilterChange={(text) => setFilter(text)}
            onFetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
          <div className="container">{deviceTags}</div>
        </div>
      </div>
      <div className="edit-block">
        <label>
          Description<span>*</span>
        </label>
        <Textarea
          sx={{ display: 'block' }}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          onBlur={() => {
            if (description === '') {
              setDescriptionError({
                isError: true,
                message: 'Description do not empty',
              });
            } else {
              setDescriptionError({
                isError: false,
                message: '',
              });
            }
          }}
          error={descriptionError.isError}
          errorMessage={descriptionError.message}
        />
      </div>
    </Container>
  );
};

export default EditIssueView;
