import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CloudUpload } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  IconButton,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ErrorState } from '../../../types';
import { Project } from '../../../types/Project';

import { updateAsset, uploadAsset } from '../../../apis/projectApi';
import { useAppSelector } from '../../../hooks';

import Input from '../../../components/Input';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      minWidth: '600px',
      '& > .header': {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        height: '64px',
        padding: '16px',
        '& > .title': {
          ...theme.typography.body1,
          flex: 1,
          fontWeight: '500',
          textAlign: 'center',
          paddingLeft: '40px',
        },
        '& > .close-icon': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        },
      },
      '& > .banner': {
        color: '#fff',
        fontSize: '1rem',
        padding: '16px',
        '&[data-status="error"]': {
          background: theme.color.highlight,
        },
        '&[data-status="success"]': {
          background: theme.color.green.$100,
        },
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    maxHeight: '640px',
    padding: '24px',
    '&:first-of-type': {
      marginTop: '0',
    },
    '& label': {
      fontSize: '0.875rem',
      color: theme.color.secondary.$60,
      '& > span': {
        color: '#FF6B00',
      },
    },
    '& .form-control': {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0',
    },
  };
});

const DialogActions = styled(MuiDialogActions)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 24px 24px',
  };
});

const Create3DModel = () => {
  const selectedProject = useAppSelector(
    (store) => store.system.selectedProject,
  );
  const navigate = useNavigate();
  const glbInputFileRef = useRef<HTMLInputElement | null>(null);
  const glbFileNameInputRef = useRef<HTMLInputElement | null>(null);
  const ifcInputFileRef = useRef<HTMLInputElement | null>(null);
  const ifcFileNameInputRef = useRef<HTMLInputElement | null>(null);
  const [modelName, setModelName] = useState('');
  const [modelNameError, setModelNameError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [glbFileName, setGlbFileName] = useState('');
  const [requestInfo, setRequestInfo] = useState<{
    status: 'default' | 'success' | 'error';
    message: string;
  }>({
    status: 'default',
    message: '',
  });

  const upload3dModeMutation = useMutation({
    mutationFn: uploadAsset,
    onMutate: () => {
      setRequestInfo({
        status: 'default',
        message: '',
      });
    },
    onSuccess: (res) => {
      updateAssetMuation.mutate({
        projectId: (selectedProject as Project).id,
        assetId: res.data.data.id,
        data: {
          name: modelName,
          coordinates: {
            lon: selectedProject?.center[0] ?? 0,
            lat: selectedProject?.center[1] ?? 0,
            alt: 0,
          },
          rotation: {
            roll: 0,
            pitch: 0,
            heading: 0,
          },
          rotationTuneParams: res.data.data.rotationTuneParams,
          scale: 1,
          heightTune: 0,
        },
      });
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        setRequestInfo({
          status: 'error',
          message: `Error(Upload model): ${
            err.response?.data?.error
              ? JSON.stringify(err.response.data.error)
              : err.message
          }`,
        });
      }
    },
  });

  const updateAssetMuation = useMutation({
    mutationFn: updateAsset,
    onMutate: () => {
      setRequestInfo({
        status: 'default',
        message: '',
      });
    },
    onSuccess: () => {
      setRequestInfo({
        status: 'success',
        message: 'Upload model sucess',
      });
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        setRequestInfo({
          status: 'error',
          message: `Error(Set model info): ${
            err.response?.data?.error
              ? JSON.stringify(err.response.data.error)
              : err.message
          }`,
        });
      }
    },
  });

  const handleOnClose = () => {
    navigate(-1);
  };

  const handleCreate = () => {
    if (selectedProject && glbInputFileRef.current?.files) {
      upload3dModeMutation.mutate({
        projectId: selectedProject?.id,
        data: {
          ifcFile: ifcInputFileRef.current?.files?.[0],
          glbFile: glbInputFileRef.current.files[0],
          name: modelName,
        },
      });
    }
  };

  const isValid = modelName !== '' && glbFileName !== '';

  return (
    <Dialog open>
      <div className="header">
        <span className="title">Create 3D Model</span>
        <div className="close-icon" onClick={handleOnClose}>
          <CrossSvgIcon sx={{ width: '40px', height: '40px' }} />
        </div>
      </div>
      {requestInfo.status !== 'default' && (
        <div className="banner" data-status={requestInfo.status}>
          {requestInfo.message}
        </div>
      )}
      <DialogContent>
        <div>
          <label>
            Model Name
            <span>*</span>
          </label>
          <Input
            sx={() => ({
              display: 'block',
            })}
            value={modelName}
            onChange={(e) => setModelName(e.currentTarget.value)}
            onBlur={() => {
              if (modelName === '') {
                setModelNameError({
                  isError: true,
                  message: 'Model name is required',
                });
              } else {
                setModelNameError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={modelNameError.isError}
            errorMessage={modelNameError.message}
          />
          <div className="form-control">
            <label>
              GLB File<span>*</span>:
            </label>{' '}
            <Input
              sx={{ flex: 1, width: '100%', margin: '0 16px' }}
              ref={glbFileNameInputRef}
              readOnly
            />
            <IconButton
              color="primary"
              onClick={() => glbInputFileRef.current?.click()}
            >
              <CloudUpload />
            </IconButton>
            <input
              ref={glbInputFileRef}
              type="file"
              accept=".glb"
              hidden
              onChange={(e) => {
                if (glbFileNameInputRef.current) {
                  glbFileNameInputRef.current.value =
                    e.currentTarget.files?.item(0)?.name ?? '';
                }
                setGlbFileName(e.currentTarget.files?.item(0)?.name ?? '');
              }}
            />
          </div>
          <div className="form-control">
            <label>IFC File:</label>{' '}
            <Input
              sx={{ flex: 1, width: '100%', margin: '0 16px' }}
              ref={ifcFileNameInputRef}
              readOnly
            />
            <IconButton
              color="primary"
              onClick={() => ifcInputFileRef.current?.click()}
            >
              <CloudUpload />
            </IconButton>
            <input
              ref={ifcInputFileRef}
              type="file"
              accept=".ifc"
              hidden
              onChange={(e) => {
                if (ifcFileNameInputRef.current) {
                  ifcFileNameInputRef.current.value =
                    e.currentTarget.files?.item(0)?.name ?? '';
                }
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={(theme) => ({ color: theme.color.primary.$100 })}
          variant="text"
          color="primary"
          onClick={handleOnClose}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={
            !isValid ||
            upload3dModeMutation.isPending ||
            updateAssetMuation.isPending
          }
          onClick={handleCreate}
        >
          {upload3dModeMutation.isPending && updateAssetMuation.isPending ? (
            <CircularProgress sx={{ color: '#fff' }} size={14} />
          ) : (
            'CREATE'
          )}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default Create3DModel;
