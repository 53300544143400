import React, { useState } from 'react';
import {
  Button,
  PasswordTextField,
} from '@beeinventor/dasiot-react-component-lib';
import { EmailOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  Container as MuiContainer,
  styled,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ErrorState } from '../../types';

import { login } from '../../apis/authApi';
import { useAppDispatch } from '../../hooks';
import { login as loginAction } from '../../slices/authSlice';

import Validator from '../../utils/Validator';

const Container = styled(MuiContainer)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'url("")',

    '& > .logo': {
      marginBottom: '67px',
    },

    '& button': {
      width: '320px',
      padding: '8px 32px',
      marginTop: '40px',
    },
  };
});

const EmailInput = styled(TextField)(({ theme }) => {
  return {
    '& .MuiInputBase-root': {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      '&.Mui-error': {
        borderColor: theme.color.red.$100,
      },
    },
    '& .MuiFilledInput-root': {
      width: '320px',
      border: '1px solid transparent',
      borderRadius: '4px',
      '&:focus-within': {
        border: `1px solid ${theme.color.primary.$100}`,
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      fontSize: '1rem',
      lineHeight: '24px',
      padding: '13.5px 24px 13.5px 8px',
      borderRadius: '4px',
    },
    '& .MuiInputBase-adornedStart': {
      color: 'white',
      paddingLeft: '24px',
      '& > .icon': {
        padding: '8px',
        '& > svg': {
          display: 'block',
        },
        marginRight: '-2px',
      },
    },
    'input:-webkit-autofill, input:-webkit-autofill:focus': {
      transition: 'background-color 600000s 0s, color 600000s 0s',
    },
  };
});

const ErrorMessage = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.625rem',
    color: theme.color.red.$100,
    textAlign: 'center',
    '& > .icon': {
      padding: '6px',
      '& > img': {
        display: 'block',
      },
    },
  };
});

const ErrorContainer = styled('div')(({ theme }) => {
  return {
    ...theme.typography.body2,
    textAlign: 'center',
    padding: '10px 0',
    backgroundColor: theme.color.red.$100,
    marginTop: '40px',
  };
});

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [requestErrorResult] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [validateEmailResult, setValidateEmailResult] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [validatePasswordResult, setValidatePasswordResult] =
    useState<ErrorState>({
      isError: false,
      message: '',
    });

  const loginMutation = useMutation({
    mutationFn: (data: { email: string; password: string }) => {
      return login(data.email, data.password);
    },
    onSuccess: (result) => {
      dispatch(
        loginAction({
          accessToken: result.data.access_token,
          expiresIn: result.data.expires_in,
          refreshToken: result.data.refresh_token,
          refreshExpiresIn: result.data.refresh_expires_in,
        }),
      );
    },
  });

  const handleOnBlurEmail = () => {
    setValidateEmailResult(Validator.validateEmail(email));
  };

  const handleOnBlurPassword = () => {
    setValidatePasswordResult(Validator.validatePassword(password));
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    loginMutation.mutate({ email, password });
  };

  return (
    <Container className="login">
      <img className="logo" src="/assets/image/png/logo.png" />
      <form onSubmit={handleOnSubmit}>
        <EmailInput
          error={validateEmailResult.isError}
          variant="filled"
          placeholder="Email"
          InputProps={{
            name: 'email',
            startAdornment: (
              <div className="icon">
                <EmailOutlined />
              </div>
            ),
          }}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          onBlur={handleOnBlurEmail}
        />
        {validateEmailResult.isError && (
          <ErrorMessage>
            <div className="icon">
              <img src="/assets/image/svg/error-icon.svg" />
            </div>
            {validateEmailResult.message}
          </ErrorMessage>
        )}
        <PasswordTextField
          sx={(theme) => ({
            marginTop: '40px',
            border: '2px solid transparent',
            '&:focus-within': {
              border: `2px solid ${theme.color.primary.$100}`,
            },
          })}
          placeholder="Password"
          inputProps={{
            name: 'password',
            value: password,
          }}
          error={validatePasswordResult.isError}
          errorMessage={validatePasswordResult.message}
          onChange={(p) => setPassword(p)}
          onBlur={handleOnBlurPassword}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            loginMutation.isPending ||
            Validator.validateEmail(email).isError ||
            Validator.validatePassword(password).isError ||
            validateEmailResult.isError ||
            validatePasswordResult.isError
          }
        >
          {loginMutation.isPending ? (
            <CircularProgress sx={{ color: 'white' }} size={24} />
          ) : (
            'Login'
          )}
        </Button>
        {requestErrorResult.isError && (
          <ErrorContainer>{requestErrorResult.message}</ErrorContainer>
        )}
      </form>
    </Container>
  );
};

export default LoginPage;
