import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { Add, DeleteForever, Menu, Remove, Update } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GeoJSON, Position } from 'geojson';
import maplibregl from 'maplibre-gl';
import { Box3, Scene, Vector3 } from 'three';

import { Model, RequestState } from '../../../types';
import { Project, ProjectMap } from '../../../types/Project';

import {
  get3dAssets,
  getProjects,
  updateAsset,
} from '../../../apis/projectApi';
import {
  useAppDispatch,
  useAppSelector,
  useCesiumElevation,
} from '../../../hooks';
import { setSelectedProject as setSelectedProjectAction } from '../../../slices/systemSlice';

import { CoordinateConverter } from '../../../utils/CoordinateConverter';
import { create3DModelLayer } from '../../../utils/mapbox';

import Delete3DModel from './Delete3DModel';

import 'maplibre-gl/dist/maplibre-gl.css';

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    height: 'calc(100vh - 74px)',
    '& label': {
      color: '#fff',
      '&.Mui-focused': {
        color: theme.color.primary.$100,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color: '#fff',
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: theme.color.primary.$80,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.color.primary.$100,
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
      '&::placeholder': {
        color: '#fff',
      },
    },
    '& .MuiButtonBase-root': {
      color: '#fff',
    },
    '& > .control-container': {
      width: '400px',
      color: '#fff',
      backgroundColor: '#3E3E3E',
      padding: '16px',
      '& > .project': {
        textAlign: 'center',
        '& .MuiAutocomplete-popper': {
          textAlign: 'start',
        },
      },
      '& > .model': {
        '& .MuiSvgIcon-root': {
          color: '#fff',
        },
      },
      '& label': {
        fontWeight: 'bold',
      },
      '&  hr': {
        borderColor: theme.color.secondary.$80,
      },
      '& .form-control': {
        display: 'flex',
        alignItems: 'center',
        margin: '8px 0',
        '& > input': {
          flex: 1,
          margin: '0 0 0 8px',
        },
      },
      '& .request-status': {
        color: '#fff',
        padding: '8px',
        margin: '8px 0',
        borderRadius: '4px',
        fontFamily: 'Noto Sans TC',
        '&[data-status="success"]': {
          backgroundColor: theme.color.green.$100,
        },
        '&[data-status="error"]': {
          backgroundColor: theme.color.highlight,
        },
      },
    },
  };
});

const MapRepper = styled('div')`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  & > .menu {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    background: #3e3e3e;
    padding: 40px 10px 10px;
    border-radius: 4px;
    ul {
      padding: 0;
      list-style: none;
    }
  }
  & #map {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
  }
`;

const ThreeDModelSettingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isHidden: isSidebarHidden } = useAppSelector(
    (store) => store.system.sidebar,
  );
  const mapboxRef = useRef<maplibregl.Map>();
  const sceneRef = useRef<Scene>();
  const markersRef = useRef<maplibregl.Marker[]>([]);
  const converterRef = useRef<CoordinateConverter>();
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [projectName, setProjectName] = useState('');
  const [models, setModels] = useState<Model[]>([]);
  const [selectedModelIndex, setSelectedModelIndex] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteModelInfo, setDeleteModelInfo] = useState<{
    isOpen: boolean;
    name: string;
  }>({
    isOpen: false,
    name: '',
  });
  const [requestInfo, setRequestInfo] = useState<RequestState>({
    status: 'default',
    message: '',
  });
  const [showMenu, setShowMenu] = useState(false);
  const [referencePoints, setReferencePoints] = useState<Array<string>>(['']);

  const { data: projectMap } = useQuery({
    queryKey: ['get-project-map'],
    queryFn: async () => {
      const res = await getProjects();
      return res.data.data.reduce<ProjectMap>((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {});
    },
    refetchOnWindowFocus: false,
  });

  const elevation = useCesiumElevation(
    selectedProject?.center[0],
    selectedProject?.center[1],
  );

  const {
    data: project3DAssets,
    refetch,
    isSuccess,
  } = useQuery({
    queryKey: ['get-project-3d-assets', selectedProject?.id, elevation],
    queryFn: async () => {
      if (selectedProject) {
        const res = await get3dAssets(selectedProject.id);
        return res.data.data;
      }
      return [];
    },
    enabled: !!selectedProject?.id && typeof elevation !== 'undefined',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess && project3DAssets) {
      setModels(project3DAssets);
      if (selectedProject && typeof elevation !== 'undefined') {
        if (mapboxRef.current?.getLayer('3d-model-layer')) {
          mapboxRef.current?.removeLayer('3d-model-layer');
        }

        if (markersRef.current.length > 0) {
          do {
            markersRef.current.pop()?.remove();
          } while (markersRef.current.length > 0);
        }

        const { layer, scene } = create3DModelLayer(
          selectedProject?.center,
          elevation,
          project3DAssets,
        );
        sceneRef.current = scene;

        mapboxRef.current?.addLayer(layer, 'project-center');
        const data: GeoJSON = {
          type: 'Point',
          coordinates: selectedProject.center,
        };
        // @ts-ignore
        mapboxRef.current?.getSource('project-center').setData(data);

        project3DAssets.forEach((model, index) => {
          if (mapboxRef.current && selectedProject) {
            const marker = new maplibregl.Marker({
              draggable: true,
            })
              .setLngLat([
                model.coordinates?.lon ?? selectedProject.center[0],
                model.coordinates?.lat ?? selectedProject.center[1],
              ])
              .addTo(mapboxRef.current);
            markersRef.current.push(marker);

            marker.on('dragend', () => {
              setSelectedModelIndex(index);
              sceneRef.current?.traverse((obj) => {
                if (obj.userData.name === model.name) {
                  const lngLat = marker.getLngLat();
                  setModels((m) => {
                    const newModels = [...m];
                    newModels[index] = {
                      ...newModels[index],
                      coordinates: {
                        lon: lngLat.lng,
                        lat: lngLat.lat,
                        alt: newModels[index].coordinates?.alt ?? 0,
                      },
                    };

                    return newModels;
                  });
                }
              });
            });
          }
        });
      }
    }
  }, [isSuccess, project3DAssets, selectedProject, elevation]);

  useEffect(() => {
    if (location.pathname === '/project-setting/3d-model') {
      refetch();
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setSelectedProjectAction(selectedProject));
    if (selectedProject) {
      converterRef.current = new CoordinateConverter(
        selectedProject.center[1],
        selectedProject.center[0],
      );
    }
  }, [selectedProject]);

  useEffect(() => {
    mapboxRef.current = new maplibregl.Map({
      container: 'map',
      style: `https://api.maptiler.com/maps/d8043b18-64f3-4617-ac68-174a90057651/style.json?key=${import.meta.env.VITE_MAP_TILER_API_KEY}`,
      center: [0, 0],
      zoom: 18,
      antialias: true,
    });

    mapboxRef.current.on('style.load', () => {
      mapboxRef.current?.addSource('project-center', {
        type: 'geojson',
        data: {
          type: 'Point',
          coordinates: [0, 0],
        },
      });

      mapboxRef.current?.addLayer({
        id: 'project-center',
        type: 'circle',
        source: 'project-center',
        paint: {
          'circle-color': 'red',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      mapboxRef.current?.addSource('reference-points', {
        type: 'geojson',
        data: {
          type: 'MultiPoint',
          coordinates: [[0, 0]],
        },
      });

      mapboxRef.current?.addLayer({
        id: 'reference-points',
        type: 'circle',
        source: 'reference-points',
        paint: {
          'circle-color': '#80ed99',
          'circle-radius': 2,
        },
      });
    });

    return () => {
      if (mapboxRef.current?.getLayer('3d-model-layer')) {
        mapboxRef.current?.removeLayer('3d-model-layer');
      }
      mapboxRef.current?.remove();
    };
  }, []);

  useEffect(() => {
    mapboxRef.current?.resize();
  }, [isSidebarHidden]);

  useEffect(() => {
    if (selectedProject) {
      mapboxRef.current?.setCenter(selectedProject.center);
      mapboxRef.current?.flyTo({
        center: selectedProject.center,
        zoom: 18,
      });
    } else {
      mapboxRef.current?.setCenter([0, 0]);
      mapboxRef.current?.flyTo({
        center: [0, 0],
        zoom: 18,
      });
    }
  }, [selectedProject]);

  const selectedModel = useMemo(() => {
    if (selectedModelIndex < models.length) {
      return models[selectedModelIndex];
    }
    return undefined;
  }, [models, selectedModelIndex]);

  useEffect(() => {
    if (models[selectedModelIndex] && typeof elevation !== 'undefined') {
      const model = models[selectedModelIndex];
      let isTaller = true;

      markersRef.current[selectedModelIndex]?.setLngLat([
        model.coordinates?.lon ?? 0,
        model.coordinates?.lat ?? 0,
      ]);

      if (
        typeof model.coordinates?.alt !== 'undefined' &&
        model.coordinates.alt < elevation
      ) {
        isTaller = false;
      }

      sceneRef.current?.traverse((obj) => {
        if (
          models.length > 0 &&
          obj.userData.name === models[selectedModelIndex].name
        ) {
          obj.position.set(0, 0, 0);
          obj.rotation.set(
            ((models[selectedModelIndex].rotation?.roll ?? 0) * Math.PI) / 180,
            ((models[selectedModelIndex].rotation?.heading ?? 0) * Math.PI) /
              180,
            ((models[selectedModelIndex].rotation?.pitch ?? 0) * Math.PI) / 180,
          );
          const aabb = new Box3().setFromObject(obj);
          const aabbCenter = aabb.getCenter(new Vector3());
          if (converterRef.current) {
            const position = converterRef.current.geographicToCartesian(
              models[selectedModelIndex].coordinates?.lat ?? 0,
              models[selectedModelIndex].coordinates?.lon ?? 0,
            );
            obj.position.set(
              position.x * -1 - aabbCenter.x,
              isTaller
                ? 0 + (model.heightTune ?? 0)
                : (model.coordinates?.alt ?? 0) -
                    elevation +
                    (model.heightTune ?? 0),
              position.z * -1 - aabbCenter.z,
            );
            obj.scale.set(
              models[selectedModelIndex]?.scale ?? 1,
              models[selectedModelIndex]?.scale ?? 1,
              models[selectedModelIndex]?.scale ?? 1,
            );
          }
        }
      });
    }
  }, [models, selectedModelIndex, elevation]);

  useEffect(() => {
    const lngLats = referencePoints
      .map((v) => {
        const token = v.split(',');
        if (
          token.length === 2 &&
          !Number.isNaN(token[0]) &&
          !Number.isNaN(token[1])
        ) {
          return [token[0], token[1]];
        }
        return null;
      })
      .filter((v) => v !== null);

    if (mapboxRef.current) {
      const data: GeoJSON = {
        type: 'MultiPoint',
        // @ts-ignore
        coordinates: lngLats as Position[],
      };
      // @ts-ignore
      mapboxRef.current.getSource('reference-points')?.setData(data);
    }
  }, [referencePoints]);

  const modelItems = useMemo(() => {
    return models.map((model, index) => [
      <MenuItem key={`model-item-${model.id}`} value={index}>
        {model.name}
      </MenuItem>,
    ]);
  }, [models]);

  const handleOnChangeModel = (
    property:
      | 'lon'
      | 'lat'
      | 'alt'
      | 'roll'
      | 'pitch'
      | 'heading'
      | 'rotationTuneParams.roll'
      | 'rotationTuneParams.pitch'
      | 'rotationTuneParams.heading'
      | 'scale'
      | 'heightTune',
    value: number,
  ) => {
    if (Number.isNaN(value)) return;

    const localModels = models.map((m, index) => {
      if (selectedModelIndex === index) {
        const newModel: Model = m;
        switch (property) {
          case 'lon':
          case 'lat':
          case 'alt':
            if (newModel.coordinates) {
              newModel.coordinates[property] = value;
            }
            break;
          case 'pitch':
          case 'heading':
          case 'roll':
            if (newModel.rotation) {
              newModel.rotation[property] = value;
            }
            break;
          case 'rotationTuneParams.pitch':
          case 'rotationTuneParams.heading':
          case 'rotationTuneParams.roll':
            if (newModel.rotationTuneParams) {
              newModel.rotationTuneParams[
                property.replace('rotationTuneParams.', '')
              ] = value;
            }
            break;
          case 'scale':
            newModel.scale = value;
            break;
          case 'heightTune':
            newModel.heightTune = value;
        }
        return newModel;
      } else {
        return m;
      }
    });

    setModels(localModels);
  };

  const handleUpdateModel = async () => {
    if (selectedProject) {
      setIsUpdating(true);
      setRequestInfo({
        status: 'default',
        message: ``,
      });
      for (let i = 0; i < models.length; i++) {
        const m = models[i];
        try {
          await updateAsset({
            projectId: selectedProject.id,
            assetId: m.id,
            data: {
              name: m.name,
              coordinates: {
                lat: m.coordinates?.lat ?? 0,
                lon: m.coordinates?.lon ?? 0,
                alt: m.coordinates?.alt ?? 0,
              },
              rotation: {
                roll: m.rotation?.roll ?? 0,
                pitch: m.rotation?.pitch ?? 0,
                heading: m.rotation?.heading ?? 0,
              },
              rotationTuneParams: {
                roll: m.rotationTuneParams?.roll ?? 0,
                pitch: m.rotationTuneParams?.pitch ?? 0,
                heading: m.rotationTuneParams?.heading ?? 0,
              },
              scale: m?.scale ?? 1,
              heightTune: m.heightTune ?? 0,
            },
          });
        } catch (err) {
          if (err instanceof AxiosError) {
            setRequestInfo({
              status: 'error',
              message: `Fail to update ${m.name}\nError: ${
                JSON.stringify(err.response?.data?.error, null, 2) ??
                err.message
              }`,
            });
          }
          return;
        }
      }

      setRequestInfo({
        status: 'success',
        message: `Update models success`,
      });
      setIsUpdating(false);

      setTimeout(() => {
        setRequestInfo({
          status: 'default',
          message: ``,
        });
      }, 2000);
    }
  };

  const handleOnClickAdd = () => {
    setReferencePoints([...referencePoints, '']);
  };

  const handleOnClickRemove = (index: number) => {
    const before = referencePoints.slice(0, index);
    const after = referencePoints.slice(index + 1);
    setReferencePoints(before.concat(after));
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newReferencePoints = [...referencePoints];
    newReferencePoints[index] = e.currentTarget.value;
    setReferencePoints(newReferencePoints);
  };

  const referencePointItems = referencePoints.map((rp, i) => {
    return (
      <div key={`reference-point-item-${i}`}>
        <input
          placeholder="Lng,Lat"
          value={rp}
          onChange={(e) => handleOnChange(e, i)}
        />
        <IconButton onClick={handleOnClickAdd}>
          <Add />
        </IconButton>
        {referencePoints.length > 1 && (
          <IconButton onClick={() => handleOnClickRemove(i)}>
            <Remove />
          </IconButton>
        )}
      </div>
    );
  });

  return (
    <>
      <Container>
        <MapRepper className="map-wrapper">
          <div id="map"></div>
          <IconButton
            sx={{ position: 'absolute', top: 0, left: 0, zIndex: 2000 }}
            onClick={() => setShowMenu(!showMenu)}
          >
            <Menu />
          </IconButton>
          {showMenu && (
            <div className="menu">
              <ul>
                <li>Reference Points</li>
              </ul>
              <div className="content">{referencePointItems}</div>
            </div>
          )}
        </MapRepper>
        <div className="control-container">
          <div className="project">
            <Autocomplete
              disablePortal
              options={Object.values<Project>(projectMap ?? {}).map((p) => ({
                label: `${p.name} | ${p.description}`,
                id: p.id,
              }))}
              value={
                selectedProject
                  ? { label: selectedProject.name, id: selectedProject.id }
                  : null
              }
              onChange={(e, v) => {
                if (projectMap && v) {
                  setSelectedProject(projectMap[v.id]);
                } else {
                  setSelectedProject(undefined);
                  setModels([]);
                }
              }}
              isOptionEqualToValue={(curr) => {
                return curr.id === selectedProject?.id;
              }}
              inputValue={projectName}
              onInputChange={(e, text) => {
                setProjectName(text);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="primary"
                  label="Selecte Project"
                />
              )}
            />
          </div>
          <div className="form-control">
            Project Center Elevation: {elevation}
          </div>
          {selectedProject && (
            <div className="form-control">
              <DialogButton
                variant="contained"
                color="primary"
                fullWidth
                endIcon={<Add />}
                onClick={() => {
                  navigate('create');
                }}
              >
                Add Model
              </DialogButton>
            </div>
          )}
          <hr />
          {selectedModel && (
            <div className="model">
              <Select
                sx={{ width: '100%' }}
                value={selectedModelIndex}
                onChange={(e) => {
                  setSelectedModelIndex(e.target.value as number);
                  mapboxRef.current?.flyTo({
                    center: [
                      models[e.target.value].coordinates.lon,
                      models[e.target.value].coordinates.lat,
                    ],
                    zoom: 18,
                  });
                }}
              >
                {modelItems}
              </Select>
              <div>
                <div className="form-control">
                  Lng:{' '}
                  <input
                    type="number"
                    step={0.000001}
                    max={180}
                    min={-180}
                    value={selectedModel.coordinates?.lon}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'lon',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Lat:{' '}
                  <input
                    type="number"
                    step={0.000001}
                    value={selectedModel.coordinates?.lat}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'lat',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Alt:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.coordinates?.alt}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'alt',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Tune Alt:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.heightTune ?? 0}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'heightTune',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <div className="form-control">
                  Roll:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.rotation?.roll}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'roll',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Pitch:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.rotation?.pitch}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'pitch',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Heading:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.rotation?.heading}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'heading',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Tune Roll:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.rotationTuneParams?.roll}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'rotationTuneParams.roll',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Tune Pitch:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.rotationTuneParams?.pitch}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'rotationTuneParams.pitch',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
                <div className="form-control">
                  Tune Heading:{' '}
                  <input
                    type="number"
                    step={0.1}
                    value={selectedModel.rotationTuneParams?.heading}
                    onChange={(e) =>
                      handleOnChangeModel(
                        'rotationTuneParams.heading',
                        parseFloat(e.currentTarget.value),
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-control">
                Scale:{' '}
                <input
                  type="number"
                  step={1}
                  value={selectedModel?.scale ?? 1}
                  onChange={(e) =>
                    handleOnChangeModel(
                      'scale',
                      parseFloat(e.currentTarget.value),
                    )
                  }
                />
              </div>
              <div className="form-control">
                <DialogButton
                  variant="contained"
                  color="warning"
                  fullWidth
                  endIcon={<DeleteForever />}
                  onClick={() => {
                    setDeleteModelInfo({
                      isOpen: true,
                      name: selectedModel.name,
                    });
                  }}
                >
                  Delete
                </DialogButton>
              </div>
              <div className="form-control">
                <DialogButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleUpdateModel}
                  disabled={isUpdating}
                  endIcon={<Update />}
                >
                  {isUpdating ? (
                    <CircularProgress
                      sx={{ color: '#fff', margin: '0 8px' }}
                      size={16}
                    />
                  ) : (
                    'Update All Models'
                  )}
                </DialogButton>
              </div>
              {requestInfo.status !== 'default' && (
                <pre
                  className="request-status"
                  data-status={requestInfo.status}
                >
                  {requestInfo.message}
                </pre>
              )}
            </div>
          )}
        </div>
      </Container>
      <Delete3DModel
        open={deleteModelInfo.isOpen}
        projectId={selectedProject?.id}
        model={selectedModel}
        onSuccess={() => {
          setSelectedModelIndex(0);
          setDeleteModelInfo({
            ...deleteModelInfo,
            isOpen: false,
          });
          refetch();
        }}
        onClose={() => {
          setDeleteModelInfo({
            ...deleteModelInfo,
            isOpen: false,
          });
        }}
      />
      <Outlet />
    </>
  );
};

export default ThreeDModelSettingPage;
