import { FC, MouseEvent, useState } from 'react';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Model, RequestState } from '../../../types';

import { deleteAsset } from '../../../apis/projectApi';

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      '& .banner': {
        color: '#fff',
        fontSize: '1rem',
        padding: '16px',
        '&[data-status="error"]': {
          background: theme.color.highlight,
        },
        '&[data-status="success"]': {
          background: theme.color.green.$100,
        },
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(() => {
  return {};
});

const DialogActions = styled(MuiDialogActions)(() => {
  return {
    justifyContent: 'space-between',
  };
});

interface Delete3DModelProps extends DialogProps {
  projectId: string | undefined;
  model: Model | undefined;
  onSuccess: () => void;
}

const Delete3DModel: FC<Delete3DModelProps> = ({
  projectId,
  model,
  onSuccess,
  ...props
}) => {
  const [requestState, setRequestState] = useState<RequestState>({
    status: 'default',
    message: '',
  });

  const deleteAssetMutation = useMutation({
    mutationFn: deleteAsset,
    onSuccess: () => {
      setRequestState({
        status: 'success',
        message: `Delete model success`,
      });
      setTimeout(() => {
        setRequestState({
          status: 'default',
          message: '',
        });
        onSuccess();
      }, 2000);
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        setRequestState({
          status: 'error',
          message: error.response?.data.error
            ? JSON.stringify(error.response?.data.error)
            : error.message,
        });
      }
    },
  });

  if (projectId && model) {
    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
      props.onClose?.(e, 'backdropClick');
    };

    const handleDeleteAsset = () => {
      deleteAssetMutation.mutate({
        projectId,
        assetId: model.id,
      });
    };

    return (
      <Dialog {...props}>
        {requestState.status !== 'default' && (
          <div className="banner" data-status={requestState.status}>
            {requestState.message}
          </div>
        )}
        <DialogContent>
          Do you want to delete <b>{`"${model.name}"`}</b> ?
        </DialogContent>
        <DialogActions>
          <DialogButton
            variant="text"
            color="primary"
            sx={(theme) => ({
              color: theme.color.primary.$100,
            })}
            onClick={handleClose}
          >
            Cancel
          </DialogButton>
          <DialogButton
            variant="contained"
            color="error"
            endIcon={
              deleteAssetMutation.isPending && (
                <CircularProgress sx={{ color: 'currentcolor' }} size={16} />
              )
            }
            disabled={deleteAssetMutation.isPending}
            onClick={handleDeleteAsset}
          >
            Delete{' '}
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

export default Delete3DModel;
