import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { RmrTicketStatus } from '../../../types/RmrTicket';

import { getRmrTicket, updateRmrTicketStatus } from '../../../apis/rmrApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setRmrTicket } from '../../../slices/rmrSlice';

import CreateRmrIssue from '../../../components/CreateRmrIssue';
import RmrButton from '../../../components/RmrButton';
import RmrCause from '../../../components/RmrCause';
import RmrIssue from '../../../components/RmrIssue';
import RmrSolution from '../../../components/RmrSolution';
import RmrStatusDropdown from '../../../components/RmrStatusDropdown';
import RmrVersionDropdown from '../../../components/RmrVersionDropdown';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';
import EditSvgIcon from '../../../components/SvgIcon/EditSvgIcon';

const Dialog = styled(MuiDialog)(() => {
  return {
    '& .MuiPaper-root': {
      width: '1000px',
      '& .loading': {
        textAlign: 'center',
        padding: '16px',
      },
    },
  };
});

const DialogTitle = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.color.box_bbg,
    padding: '12px 16px',
    '& > .device-icon': {
      display: 'block',
      marginRight: '20px',
    },

    '& > .customer-name': {
      flex: '1',
      display: 'inline-block',
      fontWeight: '500',
      marginRight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& > .ticket-contract': {
      display: 'inline-block',
      width: '100px',
      color: theme.color.primary.$100,
      fontWeight: '400',
      marginRight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& > .ticket-serial-number': {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.color.secondary.$60,
      fontWeight: '400',
      marginRight: '24px',
    },

    '& > .ticket-version': {
      display: 'inline-flex',
      alignItems: 'center',
      minWidth: '40px',
      color: theme.color.secondary.$100,
      fontWeight: '400',
      marginRight: '24px',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: '2px',
        height: '30px',
        marginRight: '20px',
        backgroundColor: theme.color.secondary.$40,
      },
      '&:after': {
        content: '""',
        display: 'inline-block',
        width: '2px',
        height: '30px',
        marginLeft: '20px',
        backgroundColor: theme.color.secondary.$40,
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    padding: '24px',
    '& .add-button': {
      width: '100%',
      '& > img': {
        display: 'inline-block',
        marginRight: '4px',
      },
    },
    '& > .main-info': {
      '& .avatar': {
        borderRadius: '50%',
        objectFit: 'cover',
      },
      '& >div': {
        marginBottom: '16px',
      },
      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        '& > div:nth-of-type(1)': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',

          '& .avatar': {
            display: 'block',
            width: '32px',
            height: '32px',
          },

          '& .issuer-name': {
            display: 'inline-block',
            margin: '0 16px',
          },

          '& .created-at': {
            color: theme.color.secondary.$80,
            '& .date': {
              display: 'inline-block',
              color: theme.color.secondary.$60,
              marginLeft: '16px',
            },
          },
        },
        '& > div:nth-of-type(2)': {
          fontSize: '0.875rem',
          color: theme.color.secondary.$80,
          '& .date': {
            display: 'inline-block',
            color: theme.color.secondary.$60,
            marginLeft: '16px',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px 0 64px',
        '& .card': {
          flex: 1,
          '& .title': {
            fontSize: '0.875',
            color: theme.color.secondary.$60,
          },
          '& .name': {
            color: theme.color.secondary.$100,
          },
        },
      },
    },
  };
});

const RmrTicketDialog = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const dispatch = useAppDispatch();
  const currentRmrTicket = useAppSelector(
    (store) => store.rmr.currentRmrTicket,
  );
  const [showCreateIssue, setShowCreateIssue] = useState(false);

  const {
    data: rmrTicket,
    isLoading,
    isSuccess,
    refetch: refetchRmrTicket,
  } = useQuery({
    queryKey: ['get-rmr-ticket', ticketId],
    queryFn: async () => {
      const res = await getRmrTicket(ticketId as string);
      return res.data.data;
    },
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess && rmrTicket) {
      dispatch(setRmrTicket(rmrTicket));
    }
  }, [isSuccess, rmrTicket, dispatch]);

  const updateStatusMutation = useMutation({
    mutationFn: (data: { ticketId: string; status: RmrTicketStatus }) => {
      return updateRmrTicketStatus(data.ticketId, data.status);
    },
    onSuccess: () => refetchRmrTicket(),
  });

  const participants = currentRmrTicket?.participants?.reduce(
    (prev, curr, index) => {
      if (index === 0) {
        return prev + curr.firstName + ' ' + curr.lastName;
      }
      return prev + ', ' + curr.firstName + ' ' + curr.lastName;
    },
    '',
  );

  const isViewRevision = rmrTicket?.version !== currentRmrTicket?.version;

  const issues = currentRmrTicket?.issues?.map((issue, issueIndex) => {
    const causes = issue.causes.map((cause, causeIndex) => {
      const solutions = cause.solutions.map((solution, solutionIndex) => {
        return (
          <RmrSolution
            key={`issue-${issue.id}-cause-${cause.id}-solution-${solution.id}`}
            disabledMoreOption={isViewRevision}
            issueId={issue.id}
            issueIndex={issueIndex}
            causeId={cause.id}
            causeIndex={causeIndex}
            solutionIndex={solutionIndex}
            data={solution}
            refetch={refetchRmrTicket}
            devicePool={issue.issueDevices}
          />
        );
      });
      return (
        <RmrCause
          key={`issue-${issue.id}-cause-${cause.id}`}
          disabledMoreOption={isViewRevision}
          issueId={issue.id}
          issueIndex={issueIndex}
          devicePool={issue.issueDevices}
          causeIndex={causeIndex}
          data={cause}
          refetch={refetchRmrTicket}
        >
          {solutions}
        </RmrCause>
      );
    });
    return (
      <RmrIssue
        key={`issue-${issue.id}`}
        disabledMoreOption={isViewRevision}
        number={issueIndex + 1}
        data={issue}
        refetch={refetchRmrTicket}
      >
        {causes}
      </RmrIssue>
    );
  });

  const handleOnSelectStatus = (status: RmrTicketStatus) => {
    if (rmrTicket) {
      updateStatusMutation.mutate({ ticketId: rmrTicket?.id, status });
    }
  };

  return (
    <>
      <Dialog open maxWidth={false}>
        <DialogTitle className="title-container">
          <RmrStatusDropdown
            sx={{ marginRight: '20px' }}
            status={currentRmrTicket?.status}
            disabled={!isViewRevision}
            onSelectStatus={handleOnSelectStatus}
          />
          <img
            className="device-icon"
            src="/assets/image/svg/rmr-hardware.svg"
          />
          <div className="customer-name" title={currentRmrTicket?.customer}>
            {currentRmrTicket?.customer}
          </div>
          <div className="ticket-contract" title={currentRmrTicket?.contract}>
            {currentRmrTicket?.contract}
          </div>
          <span className="ticket-serial-number">
            {currentRmrTicket?.serialNumber}
          </span>
          <div className="ticket-version">
            <RmrVersionDropdown
              rmrTicket={rmrTicket}
              currentRmrTicket={currentRmrTicket}
            />
          </div>
          <EditSvgIcon
            sx={{
              cursor: 'pointer',
              display: 'block',
              width: '40px',
              height: '40px',
              mr: '4px',
              '&[data-diabled="true"]': {
                cursor: 'default',
                opacity: 0.3,
              },
            }}
            data-diabled={isViewRevision}
            onClick={() => {
              if (!isViewRevision) {
                navigate('edit');
              }
            }}
          />
          <CrossSvgIcon
            sx={{
              cursor: 'pointer',
              display: 'block',
              width: '40px',
              height: '40px',
            }}
            onClick={() => {
              dispatch(setRmrTicket(undefined));
              navigate(-1);
            }}
          />
        </DialogTitle>
        {isLoading ? (
          <div className="loading">
            <CircularProgress color="primary" size={40} />
          </div>
        ) : (
          <DialogContent>
            <div className="main-info">
              <div>
                <div>
                  <img
                    className="avatar"
                    src={
                      rmrTicket?.createdBy.imageURL
                        ? rmrTicket?.createdBy.imageURL
                        : '/assets/image/svg/user_default_avatar.svg'
                    }
                  />
                  <span className="issuer-name">
                    {rmrTicket?.createdBy?.firstName}{' '}
                    {rmrTicket?.createdBy?.lastName}
                  </span>
                  <span className="created-at">
                    CREATED{' '}
                    <span className="date">
                      {rmrTicket?.createdAt
                        ? format(
                            new Date(rmrTicket.createdAt),
                            'yyyy - MM - dd HH : mm',
                          )
                        : null}
                    </span>
                  </span>
                </div>
                <div className="due-date">
                  DUE DATE{' '}
                  <span className="date">
                    {rmrTicket?.dueDate
                      ? format(new Date(rmrTicket.dueDate), 'yyyy - MM - dd')
                      : null}
                  </span>
                </div>
              </div>
              <div>
                <div className="card issuer">
                  <div className="title">Issuer</div>
                  <div className="name">{rmrTicket?.raisedBy}</div>
                </div>
                <div className="card participant">
                  <div className="title">Participant</div>
                  <div className="name">{participants}</div>
                </div>
                <div className="card auditor">
                  <div className="title">Auditor</div>
                  <div className="name">
                    {rmrTicket?.auditor?.firstName +
                      ' ' +
                      rmrTicket?.auditor?.lastName}
                  </div>
                </div>
              </div>
            </div>
            <div className="issue-list">
              {showCreateIssue ? (
                <CreateRmrIssue
                  issueIndex={rmrTicket?.issues?.length ?? 0}
                  onClose={() => {
                    setShowCreateIssue(false);
                    refetchRmrTicket();
                  }}
                />
              ) : (
                !isViewRevision && (
                  <RmrButton
                    className="add-button"
                    onClick={() => setShowCreateIssue(true)}
                  >
                    <img src="/assets/image/svg/add.svg" /> ISSUE{' '}
                    {(rmrTicket?.issues?.length ?? 0) + 1}
                  </RmrButton>
                )
              )}
              {issues}
            </div>
          </DialogContent>
        )}
      </Dialog>
      <Outlet />
    </>
  );
};

export default RmrTicketDialog;
