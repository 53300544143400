import { useState } from 'react';
import { Switch } from '@beeinventor/dasiot-react-component-lib';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  Box,
  FormControlLabel,
  ListItemIcon,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material';

import {
  DasEndpointType,
  FeatureListType,
  OtherFeatureType,
  SSSSFeatureType,
} from '../../../types/Device';
import { ProjectFeature } from '../../../types/Project';

import RadioCheckedSvgIcon from '../../../components/SvgIcon/RadioCheckedSvgIcon';
import RadioUncheckedSvgIcon from '../../../components/SvgIcon/RadioUncheckedSvgIcon';

import AttendanceSvgIcon from '../../../assets/svg/feature-list/AttendanceSvgIcon';
import CctvSvgIcon from '../../../assets/svg/feature-list/CctvSvgIcon';
import ConfinedSpaceSvgIcon from '../../../assets/svg/feature-list/ConfinedSpaceSvgIcon';
import DasairSvgIcon from '../../../assets/svg/feature-list/DasairSvgIcon';
import DasConcreteSvgIcon from '../../../assets/svg/feature-list/DasConcreteSvgIcon';
import DasgasSvgIcon from '../../../assets/svg/feature-list/DasgasSvgIcon';
import DaslockSvgIcon from '../../../assets/svg/feature-list/DaslockSvgIcon';
import DasloopSvgIcon from '../../../assets/svg/feature-list/DasloopSvgIcon';
import DaspowerSvgIcon from '../../../assets/svg/feature-list/DaspowerSvgIcon';
import DastempSvgIcon from '../../../assets/svg/feature-list/DastempSvgIcon';
import DastrackSvgIcon from '../../../assets/svg/feature-list/DastrackSvgIcon';
import DastrackVSvgIcon from '../../../assets/svg/feature-list/DastrackVSvgIcon';
import DaswaterLSvgIcon from '../../../assets/svg/feature-list/DaswaterLSvgIcon';
import DataDownloadSvgIcon from '../../../assets/svg/feature-list/DataDownloadSvgIcon';
import DefaultSvgIcon from '../../../assets/svg/feature-list/DefaultSvgIcon';
import PublicAccessSvgIcon from '../../../assets/svg/feature-list/PublicAccessSvgIcon';
import ThreeDPlusSvgIcon from '../../../assets/svg/feature-list/ThreeDPlusSvgIcon';
import TowerCraneSvgIcon from '../../../assets/svg/feature-list/TowerCraneSvgIcon';
import TunnelSvgIcon from '../../../assets/svg/feature-list/TunnelSvgIcon';
import SafieSvgIcon from '../../../assets/svg/feature-list/SafieSvgIcon';
import WebhookSvgIcon from '../../../assets/svg/WebhookSvgIcon';

interface ContainerProps {
  disabled?: boolean;
}
// prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'disabled' })<ContainerProps>`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: ${({ theme }) => theme.color.gray.$250};
  border: 1px solid ${({ theme }) => theme.color.gray.$235};
  border-radius: 8px;
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  ${({  disabled }) => {
    if (disabled)
      return {
        opacity: "0.5"
      };
  }}
`;

interface BasicContentProps {
  projectFeature:
    | {
        [type: string]: ProjectFeature;
      }
    | undefined;
  handleOnChange: (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
    checked: boolean,
  ) => void;
  setSelectedType: (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
  ) => void;
  setIsOpenSettingDialog: (isOpen: boolean) => void;
}

const BasicContent: React.FC<BasicContentProps> = ({
  projectFeature,
  handleOnChange,
  setSelectedType,
  setIsOpenSettingDialog,
}) => {
  const [attendanceType, setAttendanceType] = useState<FeatureListType>(
    projectFeature?.['tunnel'] ? 'tunnel' : 'attendance',
  );

  const isCheckedAttendance =
    !!projectFeature?.['attendance'] || !!projectFeature?.['tunnel'];

  const handleOnChangeAttendance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as FeatureListType;

    setAttendanceType(value);
    handleOnChange('attendance', value === 'attendance');
    handleOnChange('tunnel', value === 'tunnel');
  };

  const onChangeAllAttendance = (checked: any) => {
    if (checked) {
      if (attendanceType === 'attendance') {
        handleOnChange('attendance', true);
        if (projectFeature?.['tunnel']) {
          handleOnChange('tunnel', false);
        }
      } else {
        if (projectFeature?.['attendance']) {
          handleOnChange('attendance', false);
        }
        handleOnChange('tunnel', true);
      }
    } else {
      if (projectFeature?.['attendance']) {
        handleOnChange('attendance', false);
      }
      if (projectFeature?.['tunnel']) {
        handleOnChange('tunnel', false);
      }
    }
  };

  return (
    <>
      <ul>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['dasloop']}
                onChange={(_, checked) => handleOnChange('dasloop', checked)}
              />
              <DasloopSvgIcon />
              Dasloop
            </div>

            <div
              className={!projectFeature?.['dasloop'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('dasloop');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>

        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dastrack']}
              onChange={(_, checked) => handleOnChange('dastrack', checked)}
            />
            <DastrackSvgIcon />
            Dastrack
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['daspower']}
              onChange={(_, checked) => handleOnChange('daspower', checked)}
            />
            <DaspowerSvgIcon />
            Daspower
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dastemp']}
              onChange={(_, checked) => handleOnChange('dastemp', checked)}
            />
            <DastempSvgIcon />
            Dastemp
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dasair']}
              onChange={(_, checked) => handleOnChange('dasair', checked)}
            />
            <DasairSvgIcon />
            Dasair
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['daswater']}
              onChange={(_, checked) => handleOnChange('daswater', checked)}
            />
            <DaswaterLSvgIcon />
            Daswater-L
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dastrack_v']}
              onChange={(_, checked) => handleOnChange('dastrack_v', checked)}
            />
            <DastrackVSvgIcon />
            Dastrack-V
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dasgas']}
              onChange={(_, checked) => handleOnChange('dasgas', checked)}
            />
            <DasgasSvgIcon />
            Dasgas
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['daslock']}
              onChange={(_, checked) => handleOnChange('daslock', checked)}
            />
            <DaslockSvgIcon />
            Daslock
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dascas']}
              onChange={(_, checked) => handleOnChange('dascas', checked)}
            />
            <TowerCraneSvgIcon />
            DasCAS
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['dasconcrete']}
              onChange={(_, checked) => handleOnChange('dasconcrete', checked)}
            />
            <DasConcreteSvgIcon />
            DasConcrete
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['cctv']}
              onChange={(_, checked) => handleOnChange('cctv', checked)}
            />
            <CctvSvgIcon />
            CCTV
          </div>
        </li>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={isCheckedAttendance}
                onChange={(_, checked) => onChangeAllAttendance(checked)}
              />
              <AttendanceSvgIcon />
              Multi-Storey/Tunnel
            </div>
          </div>
        </li>

        <Container disabled={!isCheckedAttendance}>
          <RadioGroup
            name="radio-buttons-group"
            onChange={handleOnChangeAttendance}
            value={attendanceType}
          >
            <FormControlLabel
              value="attendance"
              control={
                <Radio
                  icon={
                    <RadioUncheckedSvgIcon
                      sx={{ width: '40px', height: '40px' }}
                    />
                  }
                  checkedIcon={
                    <RadioCheckedSvgIcon
                      sx={{ width: '40px', height: '40px' }}
                    />
                  }
                  disableRipple
                />
              }
              label={
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ListItemIcon>
                    <AttendanceSvgIcon />
                  </ListItemIcon>
                  Multi-Storey
                </Box>
              }
            />
            <div className="switch-container">
              <FormControlLabel
                value="tunnel"
                control={
                  <Radio
                    icon={
                      <RadioUncheckedSvgIcon
                        sx={{ width: '40px', height: '40px' }}
                      />
                    }
                    checkedIcon={
                      <RadioCheckedSvgIcon
                        sx={{ width: '40px', height: '40px' }}
                      />
                    }
                    disableRipple
                  />
                }
                label={
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ListItemIcon>
                      <TunnelSvgIcon />
                    </ListItemIcon>
                    <div>Tunnel</div>
                  </Box>
                }
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
                className={!projectFeature?.['tunnel'] ? 'disabled' : undefined}
                onClick={() => {
                  setSelectedType('tunnel');
                  setIsOpenSettingDialog(true);
                }}
              >
                Edit
              </Box>
            </div>
          </RadioGroup>
        </Container>

        {/* <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['tunnel']}
                onChange={(_, checked) => handleOnChange('tunnel', checked)}
              />
              <TunnelSvgIcon />
              Tunnel
            </div>
            <div
              className={!projectFeature?.['tunnel'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('tunnel');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li> */}
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['3d_plus']}
              onChange={(_, checked) => handleOnChange('3d_plus', checked)}
            />
            <ThreeDPlusSvgIcon />
            3D+
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['confined']}
              onChange={(_, checked) => handleOnChange('confined', checked)}
            />
            <ConfinedSpaceSvgIcon />
            Confined Space System
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['das_ai_box']}
              onChange={(_, checked) => handleOnChange('das_ai_box', checked)}
            />
            <CctvSvgIcon />
            AI Camera
          </div>
        </li>
        <li>
          <div className="switch-container">
            <Switch
              checked={!!projectFeature?.['safie']}
              onChange={(_, checked) => handleOnChange('safie', checked)}
            />
            <SafieSvgIcon />
            Safie
          </div>
        </li>
      </ul>
      <hr />
      <div className="title">Alarm Activation</div>
      <ul>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['cap_off']}
                onChange={(_, checked) => handleOnChange('cap_off', checked)}
              />
              <DasloopSvgIcon />
              Cap Off
            </div>
            <div
              className={!projectFeature?.['cap_off'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('cap_off');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['connection_lost']}
                onChange={(_, checked) =>
                  handleOnChange('connection_lost', checked)
                }
              />
              <DasloopSvgIcon />
              Lost Connect
            </div>
            <div
              className={
                !projectFeature?.['connection_lost'] ? 'disabled' : undefined
              }
              onClick={() => {
                setSelectedType('connection_lost');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['notification']}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    opacity: 0.4,
                  },
                }}
                onChange={() => {
                  handleOnChange('notification', true);
                }}
              />
              <ReportProblemIcon sx={{ width: 32, height: 32 }} />
              Display Notification
            </div>
            <div
              className={
                !projectFeature?.['notification'] ? 'disabled' : undefined
              }
              onClick={() => {
                setSelectedType('notification');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
      </ul>
      <hr />
      <div className="title">Other Feature</div>
      <ul>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['report']}
                onChange={(_, checked) => handleOnChange('report', checked)}
              />
              <DataDownloadSvgIcon />
              Data Download
            </div>
            <div
              className={!projectFeature?.['report'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('report');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['dascart']}
                onChange={(_, checked) => handleOnChange('dascart', checked)}
              />
              <DefaultSvgIcon />
              Dascart
            </div>
            <div
              className={!projectFeature?.['dascart'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('dascart');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['public_access']}
                onChange={(_, checked) =>
                  handleOnChange('public_access', checked)
                }
              />
              <PublicAccessSvgIcon />
              Access Token
            </div>
            <div
              className={
                !projectFeature?.['public_access'] ? 'disabled' : undefined
              }
              onClick={() => {
                setSelectedType('public_access');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
        <li>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['webhook']}
                onChange={(_, checked) => handleOnChange('webhook', checked)}
              />
              <WebhookSvgIcon />
              WebHook
            </div>
            <div
              className={!projectFeature?.['webhook'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('webhook');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default BasicContent;
