import { useEffect, useMemo, useRef, useState } from 'react';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { IconButton, Pagination, styled } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getDasendpointNetworkReport } from '../../../apis/deviceApi';

import ReportItem from './ReportItem';

const Container = styled('div')`
  flex: 1;
  display: flex;
  min-height: 0px;
  flex-direction: column;
  & .list {
    flex: 1;
    overflow: auto;
    min-height: 0px;
  }

  & .control {
    display: flex;
    align-items: center;
    & .operation-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

interface NetworkReportContentProps {
  fromDate: Date;
  toDate: Date;
  dasId: string;
  enableQuery: boolean;
  filterRegex?: RegExp;
}

const NetworkReportContent: React.FC<NetworkReportContentProps> = ({
  fromDate,
  toDate,
  dasId,
  enableQuery,
  filterRegex,
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const { data, refetch, hasNextPage, fetchNextPage, isLoading, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-dasendpoint-network-reports', dasId, fromDate, toDate],
      queryFn: async ({ pageParam }: { pageParam?: string }) => {
        const res = await getDasendpointNetworkReport({
          dasId: dasId as string,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          limit: 200,
          nextCursor: pageParam,
        });
        return res.data;
      },
      getNextPageParam: (page) => page.paging.nextCursor,
      getPreviousPageParam: (page) => page.paging.prevCursor,
      enabled: enableQuery,
      initialPageParam: undefined,
    });

  const totalPage = useMemo(() => {
    if (data?.pages) {
      if (data.pages.length > 0 && data.pages[0].paging.nextCursor) {
        return Math.ceil(
          data.pages[0].metadata.total / data.pages[0].data.length,
        );
      } else {
        return 1;
      }
    }

    return 0;
  }, [data]);

  useEffect(() => {
    if (!data?.pages[page - 1] && hasNextPage) {
      fetchNextPage();
    }
  }, [page, data, hasNextPage]);

  const networkReportItems = (data?.pages[page - 1]?.data ?? []).map((d) => (
    <ReportItem
      key={`network-report-${d.timestamp}`}
      data={d}
      filterRegex={filterRegex}
    />
  ));

  return (
    <Container className="network-report-container">
      <div className="control">
        Total: {data?.pages[0]?.metadata?.total}
        <div className="operation-container">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
          <Pagination
            count={totalPage}
            page={page}
            onChange={(v, page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
      <div
        ref={listRef}
        className="list"
        style={{ textAlign: isLoading || isFetching ? 'center' : undefined }}
      >
        {(enableQuery && isLoading) || isFetching
          ? 'Loading...'
          : networkReportItems.length > 0
            ? networkReportItems
            : 'No Data'}
      </div>
    </Container>
  );
};

export default NetworkReportContent;
