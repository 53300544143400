import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ErrorState } from '../types';
import { DasEndpoint, DasEndpointType } from '../types/Device';
import { RmrIssue as RmrIssueData } from '../types/RmrTicket';

import { getDasEndpoints } from '../apis/dasEndpointApi';
import { updateRmrIssue, UpdateRmrIssueData } from '../apis/rmrApi';

import DeviceAssignment from '../components/DeviceAssignment';
import DeviceTag from '../components/DeviceTag';
import Input from '../components/Input';
import Textarea from '../components/Textarea';

import { inputSx, rmrIssueCloseButtonSx } from '../theme';
import { parseDasId } from '../utils/common';

import CrossSvgIcon from './SvgIcon/CrossSvgIcon';
import SaveSvgIcon from './SvgIcon/SaveSvgIcon';

const Container = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    backgroundColor: theme.color.gray.$250,
    padding: '16px 20px',
    border: `1px solid ${theme.color.gray.$235}`,
    borderRadius: '8px',
    marginTop: '16px',
    '& > .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      margin: '32px 0 16px 0',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
    '& > .edit-block': {
      marginBottom: '16px',
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& > label': {
        fontSize: '0.875rem',
        color: theme.color.secondary.$60,
        '& > span': {
          color: '#FF6B00',
        },
      },
      '& > .device-wrapper': {
        margin: '8px 0 16px 0',
        '& > .container': {
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '8px',
          marginTop: '8px',
        },
      },
      '&.action': {
        textAlign: 'center',
      },
    },
  };
});

interface EditRmrIssueProps {
  data: RmrIssueData;
  issueIndex: number;
  onCancel?: () => void;
  onSuccess?: () => void;
}

const EditRmrIssue: FC<EditRmrIssueProps> = ({
  data,
  issueIndex,
  onCancel,
  onSuccess,
}) => {
  const { ticketId } = useParams();
  const [reportIssue, setReportIssue] = useState(data.title);
  const [reportIssueError, setReportIssueError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [description, setDescription] = useState(data.description);
  const [descriptionError, setDescriptionError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [filter, setFilter] = useState('');
  const [selectedDasIds, setSelectedDasIds] = useState<string[]>(
    data.issueDevices,
  );
  const [responseSatusResult, setResponseSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });
  const editIssueMutation = useMutation({
    mutationFn: async (data: {
      ticketId: string;
      data: UpdateRmrIssueData;
    }) => {
      return updateRmrIssue(data.ticketId, data.data);
    },
    onMutate: () => {
      setResponseSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Edit issue success',
      });
      setTimeout(() => {
        onCancel?.();
        onSuccess?.();
      }, 1000);
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        setResponseSatusResult({
          status: 'error',
          message: error.response?.data?.error?.message ?? error.message,
        });
      }
    },
  });

  const {
    data: getEndpointsResponse,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['get-dasendpoint', filter],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const res = await getDasEndpoints({
        nextCursor: pageParam as string | null,
        limit: 10,
        dasId: filter,
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => lastPage?.paging?.nextCursor || undefined,
    refetchOnWindowFocus: false,
    initialPageParam: undefined,
  });

  const dasendpoints = useMemo(() => {
    return getEndpointsResponse?.pages
      .map((page) => {
        return page.data;
      })
      .flat()
      .reduce<{ [dasId: string]: DasEndpoint }>((prev, dasendpoint) => {
        prev[dasendpoint.dasId] = dasendpoint;
        return prev;
      }, {});
  }, [getEndpointsResponse?.pages, selectedDasIds]);

  const handleOnDeleteSelectedDasId = (dasId: string) => {
    setSelectedDasIds(selectedDasIds.filter((id) => id !== dasId));
  };

  const deviceTags = selectedDasIds.map((dasId) => {
    return (
      <DeviceTag
        key={`devicetags-${issueIndex}-${dasId}`}
        dasId={dasId}
        mode="edit"
        type={parseDasId(dasId) as DasEndpointType}
        onDelete={handleOnDeleteSelectedDasId}
      />
    );
  });

  const handleOnSelect = (dasId: string) => {
    setSelectedDasIds([...selectedDasIds, dasId]);
  };

  const handleEditIssue = () => {
    if (ticketId) {
      const removeDasIds: string[] = [];
      const addDasIds: string[] = [];

      data.issueDevices.forEach((id) => {
        let isExist = false;
        for (let i = 0; i < selectedDasIds.length; i++) {
          if (id === selectedDasIds[i]) {
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          removeDasIds.push(id);
        }
      });

      selectedDasIds.forEach((id) => {
        let isExist = false;
        for (let i = 0; i < data.issueDevices.length; i++) {
          if (id === data.issueDevices[i]) {
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          addDasIds.push(id);
        }
      });

      editIssueMutation.mutate({
        ticketId,
        data: {
          id: data.id,
          title: reportIssue,
          dasIds: {
            $add: addDasIds,
            $remove: removeDasIds,
          },
          description,
        },
      });
    }
  };

  const isValid =
    reportIssue !== '' && selectedDasIds.length > 0 && description !== '';

  return (
    <Container className="edit-issue-view">
      <CrossSvgIcon sx={rmrIssueCloseButtonSx} onClick={onCancel} />
      <div className="edit-block">
        <label>
          Report Issue {issueIndex + 1}
          <span>*</span>
        </label>
        <Input
          sx={{ ...inputSx, '& > input': { backgroundColor: '#fff' } }}
          value={reportIssue}
          onChange={(e) => setReportIssue(e.currentTarget.value)}
          onBlur={() => {
            if (reportIssue === '') {
              setReportIssueError({
                isError: true,
                message: 'Report issue do not empty',
              });
            } else {
              setReportIssueError({
                isError: false,
                message: '',
              });
            }
          }}
          error={reportIssueError.isError}
          errorMessage={reportIssueError.message}
        />
      </div>
      <div className="edit-block">
        <label>
          Associated Devices<span>*</span>
        </label>
        <div className="device-wrapper">
          <DeviceAssignment
            prefixKey={`create-issue-${issueIndex + 1}`}
            filter={filter}
            assignMap={dasendpoints}
            onSelect={handleOnSelect}
            selectedList={selectedDasIds}
            onFilterChange={(text) => setFilter(text)}
            onFetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
          <div className="container">{deviceTags}</div>
        </div>
      </div>
      <div className="edit-block">
        <label>
          Description<span>*</span>
        </label>
        <Textarea
          sx={{ display: 'block' }}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          onBlur={() => {
            if (description === '') {
              setDescriptionError({
                isError: true,
                message: 'Description do not empty',
              });
            } else {
              setDescriptionError({
                isError: false,
                message: '',
              });
            }
          }}
          error={descriptionError.isError}
          errorMessage={descriptionError.message}
        />
      </div>
      <div className="edit-block action">
        <DialogButton
          sx={{ padding: '0' }}
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={handleEditIssue}
          endIcon={
            !editIssueMutation.isPending && (
              <SaveSvgIcon sx={{ width: '32px', height: '32px' }} />
            )
          }
        >
          {editIssueMutation.isPending ? (
            <CircularProgress sx={{ color: '#FFF' }} size={24} />
          ) : (
            'CREATE'
          )}
        </DialogButton>
      </div>
      {responseSatusResult.message !== '' && (
        <div className="banner" data-status={responseSatusResult.status}>
          {responseSatusResult.message}
        </div>
      )}
    </Container>
  );
};

export default EditRmrIssue;
