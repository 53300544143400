import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { me } from './apis/authApi';
import { useAppDispatch, useAppSelector } from './hooks';
import { setUser } from './slices/userSlice';

const Container = styled('div')(() => {
  return {
    '& .loading': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
    },
  };
});

const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = useAppSelector((store) => store.auth.accessToken);
  const expiresIn = useAppSelector((store) => store.auth.expiresIn);
  const [isReady, setIsReady] = useState(false);
  const now = Date.now();

  useEffect(() => {
    if (!accessToken && location.pathname !== '/login') {
      navigate('/login', { replace: true });
    } else if (expiresIn) {
      if (expiresIn > now) {
        if (location.pathname === '/login') {
          navigate('/', { replace: true });
        }
      }
    }
  }, [location, accessToken, expiresIn, now]);

  const {
    data: meData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      const result = await me();
      return result.data;
    },
    enabled: !!accessToken,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (isSuccess && meData) {
      dispatch(setUser(meData.data));
      setIsReady(true);
    }
  }, [isSuccess, meData]);

  return (
    <Container className="App">
      {accessToken && isLoading && (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      )}
      {(!accessToken || isReady) && <Outlet />}
    </Container>
  );
};

export default App;
