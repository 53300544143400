import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { Dialog, DialogContent, styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns';

import { ErrorState } from '../../../types';

import { generateQrcodePath } from '../../../apis/dasEndpointApi';
import { useAppSelector } from '../../../hooks';

import LoadingDialog from '../../../components/LoadingDialog';

import StatisticsDevicesBlock from '../StatisticsDevicesBlock';

const Container = styled('div')`
  padding: 10px 30px;
  & > .title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.5;
    text-align: center;
  }

  & > .device-list {
    max-height: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px;
    margin: 8px 0;
    overflow: auto;

    & > .device-item {
      display: flex;
      align-items: center;
      & > .number {
        display: inline-block;
        min-width: 50px;
        font-size: 1.5rem;
        text-align: center;
      }
      & > .das-type {
        margin: 0 10px;
      }
      & > .device-type {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
  }

  & > .search-container {
    text-align: center;
    & > .error-message {
      text-align: center;
      color: ${({ theme }) => theme.color.highlight};
    }
  }

  & > .action {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 14px;
  }
`;

const ExportQrcodeDialog = () => {
  const navigate = useNavigate();
  const deviceMap = useAppSelector((store) => store.system.transferDeviceMap);
  const [csv, setCsv] = useState<{
    url: string;
    timestamp: Date;
  }>();
  const [errorState, setErrorState] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const exportQrcodeMutation = useMutation({
    mutationFn: generateQrcodePath,
    onMutate: () => {
      setCsv(undefined);
      setErrorState({
        isError: false,
        message: '',
      });
    },
    onSuccess: (res, requestData) => {
      let csvContent = 'DasId,QR Code Path\r\n';
      res.data.data.forEach((url, index) => {
        csvContent += `${requestData.dasIds[index]},${url}\r\n`;
      });
      const blob = new Blob([csvContent], {
        type: 'data:text/csv;charset=utf-8,',
      });
      const objUrl = URL.createObjectURL(blob);
      setCsv({
        url: objUrl,
        timestamp: new Date(),
      });
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response) {
        setErrorState({
          isError: true,
          message: JSON.stringify(error.response.data.error),
        });
      } else {
        setErrorState({
          isError: true,
          message: JSON.stringify((error as Error).message),
        });
      }
    },
  });

  const handleClickCancel = () => {
    if (csv?.url) {
      URL.revokeObjectURL(csv.url);
    }
    navigate(-1);
  };

  const handleClickExport = async () => {
    if (csv?.url) {
      URL.revokeObjectURL(csv.url);
    }
    const dasIds = Object.entries(deviceMap)
      .filter(([, d]) => d?.isSelected)
      .map(([dasId]) => dasId);
    exportQrcodeMutation.mutate({
      dasIds,
      host: import.meta.env.VITE_DSM_URL,
    });
  };

  return (
    <>
      <Dialog open>
        <DialogContent>
          <Container>
            <div className="title">Export QR Code</div>
            <StatisticsDevicesBlock deviceMap={deviceMap} />
            <div className="search-container">
              <label>
                {csv ? (
                  <>
                    Please click to download CSV
                    <br />
                    <a
                      href={csv.url}
                      download={`${format(
                        csv.timestamp,
                        'yyyy-MM-dd_HH-mm-ss',
                      )}-export-qrcode.csv`}
                    >{`${format(
                      csv.timestamp,
                      'yyyy-MM-dd_HH-mm-ss',
                    )}-export-qrcode.csv`}</a>
                  </>
                ) : (
                  "Do you want to export devices' QR Code ?"
                )}
              </label>
              {errorState.isError && (
                <div className="error-message">{errorState.message}</div>
              )}
            </div>
            <div className="action">
              <DialogButton
                sx={(theme) => ({ color: theme.color.primary.$100 })}
                color="primary"
                onClick={handleClickCancel}
              >
                Cancel
              </DialogButton>
              <DialogButton
                variant="contained"
                color="primary"
                onClick={handleClickExport}
              >
                Export
              </DialogButton>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
      {exportQrcodeMutation.isPending && (
        <LoadingDialog isLoading={exportQrcodeMutation.isPending} open />
      )}
    </>
  );
};

export default ExportQrcodeDialog;
