import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { endOfDay } from 'date-fns';

import { ErrorState } from '../../../types';
import { User } from '../../../types/User';

import {
  getParticipants,
  getRmrTicket,
  updateRmrTicket,
  UpdateRmrTicketData,
} from '../../../apis/rmrApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setRmrTicket } from '../../../slices/rmrSlice';
import { setMemberMap } from '../../../slices/systemSlice';

import Assignment from '../../../components/Assignment';
import Input from '../../../components/Input';
import MemeberTag from '../../../components/MemberTag';
import RmrVersionDropdown from '../../../components/RmrVersionDropdown';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';
import SaveSvgIcon from '../../../components/SvgIcon/SaveSvgIcon';

import { datePickerPopperSx, inputSx } from '../../../theme';

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      width: '1000px',
      maxWidth: '1000px',
      '& .loading': {
        textAlign: 'center',
        padding: '16px',
      },
    },
    '& .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
  };
});

const DialogTitle = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.color.box_bbg,
    padding: '12px 16px',
    '& > .device-icon': {
      display: 'block',
      marginRight: '20px',
    },
    '& > .customer-name': {
      flex: '1',
      display: 'inline-block',
      fontWeight: '500',
      marginRight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& > .ticket-contract': {
      display: 'inline-block',
      width: '100px',
      color: theme.color.primary.$100,
      fontWeight: '400',
      marginRight: '20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& > .ticket-serial-number': {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.color.secondary.$60,
      fontWeight: '400',
      marginRight: '24px',
    },
    '& > .ticket-version': {
      display: 'inline-flex',
      alignItems: 'center',
      minWidth: '40px',
      color: theme.color.secondary.$100,
      fontWeight: '400',
      marginRight: '24px',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: '2px',
        height: '30px',
        marginRight: '20px',
        backgroundColor: theme.color.secondary.$40,
      },
      '&:after': {
        content: '""',
        display: 'inline-block',
        width: '2px',
        height: '30px',
        marginLeft: '20px',
        backgroundColor: theme.color.secondary.$40,
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    padding: '24px',
    '& > div': {
      marginBottom: '16px',
      '&:last-of-type': {
        margin: 0,
      },
      '& > label': {
        fontSize: '0.875rem',
        color: theme.color.secondary.$60,
        '& > span': {
          color: '#FF6B00',
        },
      },
      '& > .member-wrapper': {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '8px',
        margin: '8px 0 16px 0',
      },
      '&.action': {
        textAlign: 'center',
      },
    },
  };
});

const EditRmrTicketDialog = () => {
  const { ticketId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRmrTicket = useAppSelector(
    (store) => store.rmr.currentRmrTicket,
  );
  const memberMap = useAppSelector((store) => store.system.memberMap);
  const [customerName, setCustomerName] = useState('');
  const [customerNameError, setCustomerNameError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [contractNumber, setContractNumber] = useState('');
  const [contractNumberError, setContractNumberError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [dueDate, setDueDate] = useState<Date | null | undefined>(
    endOfDay(new Date()),
  );
  const [dueDateError, setDueDateError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [proposer, setProposer] = useState('');
  const [proposerError, setProposerError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [selectedParticipantIds, setSelectedParticipantIds] = useState<
    string[]
  >([]);
  const [selectedAuditorIds, setSelectedAuditorIds] = useState<string[]>([]);
  const [resposeSatusResult, setResposeSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });

  const updateRmrTicketMutation = useMutation({
    mutationFn: async (data: UpdateRmrTicketData) => {
      return updateRmrTicket(ticketId as string, data);
    },
    onMutate: () => {
      setResposeSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResposeSatusResult({
        status: 'success',
        message: 'Update success',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResposeSatusResult({
        status: 'error',
        message: error.response?.data.error.message ?? error.message,
      });
    },
  });

  const { data: members, isSuccess: isMembersSuccess } = useQuery({
    queryKey: ['get-member'],
    queryFn: async () => {
      const result = await getParticipants();
      return result.data.data;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isMembersSuccess && members) {
      const map = members.reduce<{ [id: string]: User }>((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {});

      dispatch(setMemberMap(map));
    }
  }, [isMembersSuccess, members, dispatch]);

  const { data: ticket, isSuccess: isTicketSuccess } = useQuery({
    queryKey: ['get-rmr-ticket', ticketId],
    queryFn: async () => {
      const res = await getRmrTicket(ticketId as string);
      return res.data.data;
    },
    enabled: !!ticketId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isTicketSuccess && ticket) {
      dispatch(setRmrTicket(ticket));
      setCustomerName(ticket.customer);
      setContractNumber(ticket.contract);
      setDueDate(new Date(ticket.dueDate));
      setProposer(ticket.raisedBy);
      setSelectedParticipantIds(ticket.participants.map((p) => p.id));
      setSelectedAuditorIds([ticket.auditor.id]);
    }
  }, [isTicketSuccess, ticket, dispatch]);

  const handleOnSelectParticipant = useCallback((id: string) => {
    setSelectedParticipantIds((pArr) => [...pArr, id]);
  }, []);

  const handleOnRemoveSelectedParticipantId = useCallback((id: string) => {
    setSelectedParticipantIds((pArr) => pArr.filter((pId) => pId !== id));
  }, []);

  const handleOnSelectAuditor = useCallback((id: string) => {
    setSelectedAuditorIds((arr) => [...arr, id]);
  }, []);

  const handleOnRemoveSelectedAuditorId = useCallback((id: string) => {
    setSelectedAuditorIds((arr) => arr.filter((aId) => aId !== id));
  }, []);

  const participantTags = selectedParticipantIds
    .filter((id) => {
      return !!memberMap[id];
    })
    .map((id) => {
      return (
        <MemeberTag
          key={`participant-${id}`}
          name={`${memberMap[id].firstName} ${memberMap[id].lastName}`}
          onDelete={() => {
            handleOnRemoveSelectedParticipantId(id);
          }}
        />
      );
    });

  const auditorTags = selectedAuditorIds
    .filter((id) => {
      return !!memberMap[id];
    })
    .map((id) => {
      return (
        <MemeberTag
          key={`auditor-${id}`}
          name={`${memberMap[id].firstName} ${memberMap[id].lastName}`}
          onDelete={() => {
            handleOnRemoveSelectedAuditorId(id);
          }}
        />
      );
    });

  const isValid =
    customerName !== '' &&
    contractNumber !== '' &&
    !!dueDate &&
    proposer !== '' &&
    selectedParticipantIds.length > 0 &&
    selectedAuditorIds.length === 1;

  const handleUpdateRmrTicket = () => {
    const orginalParticipants =
      currentRmrTicket?.participants.map((user) => user.id) ?? [];

    const $remove: string[] = [];
    const $add: string[] = [];

    selectedParticipantIds.forEach((id) => {
      let isExist = false;

      for (let i = 0; i < orginalParticipants.length; i++) {
        if (id === orginalParticipants[i]) {
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        $add.push(id);
      }
    });

    orginalParticipants.forEach((id) => {
      let isExist = false;

      for (let i = 0; i < selectedParticipantIds.length; i++) {
        if (id === selectedParticipantIds[i]) {
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        $remove.push(id);
      }
    });

    if (isValid) {
      updateRmrTicketMutation.mutate({
        customer: customerName,
        contract: contractNumber,
        dueDate: dueDate.toISOString(),
        raisedBy: proposer,
        participants: {
          $remove,
          $add,
        },
        auditor: selectedAuditorIds[0],
      });
    }
  };

  return (
    <Dialog open>
      <DialogTitle className="title-container">
        <img className="device-icon" src="/assets/image/svg/rmr-hardware.svg" />
        <div className="customer-name" title={currentRmrTicket?.customer}>
          {currentRmrTicket?.customer}
        </div>
        <div className="ticket-contract" title={currentRmrTicket?.contract}>
          {currentRmrTicket?.contract}
        </div>
        <span className="ticket-serial-number">
          {currentRmrTicket?.serialNumber}
        </span>
        <div className="ticket-version">
          <RmrVersionDropdown
            rmrTicket={currentRmrTicket}
            currentRmrTicket={currentRmrTicket}
            disabled
          />
        </div>
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            display: 'block',
            width: '40px',
            height: '40px',
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
      </DialogTitle>
      {resposeSatusResult.message !== '' && (
        <div className="banner" data-status={resposeSatusResult.status}>
          {resposeSatusResult.message}
        </div>
      )}
      <DialogContent>
        <div>
          <label>
            Customer Name<span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={customerName}
            onChange={(e) => setCustomerName(e.currentTarget.value)}
            onBlur={() => {
              if (customerName === '') {
                setCustomerNameError({
                  isError: true,
                  message: 'Customer name do not empty',
                });
              } else {
                setCustomerNameError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={customerNameError.isError}
            errorMessage={customerNameError.message}
          />
        </div>
        <div>
          <label>
            Contract Number<span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={contractNumber}
            onChange={(e) => setContractNumber(e.currentTarget.value)}
            onBlur={() => {
              if (contractNumber === '') {
                setContractNumberError({
                  isError: true,
                  message: 'Contract number do not empty',
                });
              } else {
                setContractNumberError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={contractNumberError.isError}
            errorMessage={contractNumberError.message}
          />
        </div>
        <div>
          <label>
            Due Date<span>*</span>
          </label>
          <DatePicker
            onChange={(date) => {
              if (date instanceof Date && !isNaN(date.getTime())) {
                setDueDate(endOfDay(date));
              }
            }}
            value={dueDate}
            slotProps={{
              popper: {
                sx: datePickerPopperSx,
              },
              textField: {
                size: 'small',
                fullWidth: true,
                error: dueDateError.isError,
                helperText: dueDateError.message,
                placeholder: 'YYYY-MM-DD',
                onBlur: () => {
                  if (!dueDate) {
                    setDueDateError({
                      isError: true,
                      message: 'Due date do not empty',
                    });
                  } else {
                    setDueDateError({
                      isError: false,
                      message: '',
                    });
                  }
                },
              },
            }}
          />
        </div>
        <div>
          <label>
            Proposer<span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={proposer}
            onChange={(e) => setProposer(e.currentTarget.value)}
            onBlur={() => {
              if (proposer === '') {
                setProposerError({
                  isError: true,
                  message: 'Proposer do not empty',
                });
              } else {
                setProposerError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={proposerError.isError}
            errorMessage={proposerError.message}
          />
        </div>
        <div>
          <label>
            Participants<span>*</span>
          </label>
          <div className="member-wrapper">
            <Assignment
              prefixKey="participant"
              memberMap={memberMap}
              selectedList={selectedParticipantIds}
              onSelect={handleOnSelectParticipant}
            />
            {participantTags}
          </div>
        </div>
        <div>
          <label>
            Auditor<span>*</span>
          </label>
          <div className="member-wrapper">
            <Assignment
              prefixKey="auditor"
              memberMap={memberMap}
              selectedList={selectedAuditorIds}
              disabled={selectedAuditorIds.length >= 1}
              onSelect={handleOnSelectAuditor}
            />
            {auditorTags}
          </div>
        </div>
        <div className="action">
          <DialogButton
            sx={{ padding: '0' }}
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={handleUpdateRmrTicket}
            endIcon={
              !updateRmrTicketMutation.isPending && (
                <SaveSvgIcon sx={{ width: '32px', height: '32px' }} />
              )
            }
          >
            {updateRmrTicketMutation.isPending ? (
              <CircularProgress sx={{ color: '#FFF' }} size={24} />
            ) : (
              'SAVE'
            )}
          </DialogButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditRmrTicketDialog;
