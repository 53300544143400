import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getProjects } from '../../apis/projectApi';
import { useAppSelector } from '../../hooks';

import ProjectItem from './ProjectItem';

const Conatiner = styled('div')`
  height: calc(100vh - 74px);
  padding: 20px 48px;
  overflow: auto;
`;

const FeatureListPage = () => {
  const { text } = useAppSelector((store) => store.system.filter);
  const { data: projects } = useQuery({
    queryKey: ['get-projects'],
    queryFn: async () => {
      const res = await getProjects();
      return res.data.data || [];
    },
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const projectItems = useMemo(() => {
    if (projects.length > 0) {
      return projects
        .filter((project) => {
          if (text === '') return true;
          return project.name.includes(text);
        })
        .map((project, index) => {
          return (
            <ProjectItem
              key={`project-item-${project.id}`}
              order={index + 1}
              project={project}
            />
          );
        });
    }
    return null;
  }, [projects, text]);

  return (
    <>
      <Conatiner>{projectItems}</Conatiner>
      <Outlet />
    </>
  );
};

export default FeatureListPage;
